<template>
  <div
    v-if="startTime"
    class="flex h-full flex-col items-center justify-center p-2 text-lg text-white"
  >
    <div v-if="appointment.status === APPOINTMENT_STATUS.pending">
      <span
        class="mb-3 rounded border border-red-200 bg-red-100 p-4 text-red-900"
      >
        This is a pending booking
      </span>
    </div>
    <div
      v-if="!hasStarted && appointment.status !== APPOINTMENT_STATUS.pending"
    >
      <p>Time until start</p>
      <p class="text-2xl font-black">
        {{ timeUntilStart }}
      </p>
    </div>
    <p />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APPOINTMENT_STATUS } from '@/utils/schedule-utils';

export default {
  data() {
    return {
      now: new Date(),
      timeUntilStart: '',
      hasStarted: false,
      intervalCountdown: null,
      APPOINTMENT_STATUS: APPOINTMENT_STATUS,
    };
  },
  computed: {
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
    }),
    startTime() {
      return this.appointment && this.appointment.booking
        ? this.appointment.booking.booking_datetime
        : null;
    },
  },
  mounted() {
    this.intervalCountdown = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalCountdown);
  },

  methods: {
    updateTime() {
      if (!this.hasStarted) {
        if (this.startTime) {
          const start = new Date(this.startTime).getTime();
          const now = new Date().getTime();
          const diff = start - now;
          if (diff < 0) {
            this.timeUntilStart = `The appointment has started`;
            this.hasStarted = true;
          } else {
            this.hasStarted = false;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
              (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            this.timeUntilStart =
              // eslint-disable-next-line prefer-template
              (days ? `${days} days, ` : '') +
              (hours ? `${hours} hours, ` : '') +
              (minutes ? `${minutes} minutes, ` : '') +
              `${seconds} seconds`;
          }
        } else {
          this.timeUntilStart = 'Appointment time is not set.';
        }
      } else {
        clearInterval(this.intervalCountdown);
      }
    },
  },
};
</script>
