<template>
  <div class="text-gray-300">
    <div class="select-none text-sm">
      <span>Customer: </span>
      <span
        v-if="userConnected"
        class="font-semibold"
      >
        Connected
      </span>
      <span
        v-else-if="userIsInApp"
        class="font-semibold"
      >
        Outside room
      </span>
      <span
        v-else
        class="font-semibold"
      >
        Offline
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      statusCheckingRunning: false,
      firstTimeLoading: false,
      intervalStatus: null,
      loading: false,
      userIsOnline: false,
      userIsInApp: false,
    };
  },

  computed: {
    ...mapState('opentok', { opentokOnline: 'animalOwnerOnline' }),
    ...mapState('appointment', ['appointment']),
    ...mapState('journal', ['journal']),

    runCheck() {
      return !this.checkIfTimePassed && !this.journalIsFinished;
    },
    userConnected() {
      return this.opentokOnline || this.userIsOnline;
    },
    checkIfTimePassed() {
      if (this.appointment.booking) {
        return (
          this.$checkIfTimeHasPassed(
            this.appointment.booking.booking_datetime,
            0
          ) || this.journalIsFinished
        );
      }
      return false;
    },
    journalIsFinished() {
      return this.journal.status > 0;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.intervalStatus = setInterval(this.getCustomerOnlineStatus, 10000);
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalStatus);
  },

  methods: {
    getCustomerOnlineStatus() {
      if (
        this.runCheck &&
        !this.opentokOnline &&
        this.appointment.customer_id
      ) {
        this.loading = true;
        console.log('Checking user online status..', this.intervalStatus);
        axios
          .post('/api/checks/user-is-online', {
            user_id: this.appointment.customer_id,
          })
          .then(res => {
            this.loading = false;
            this.userIsInApp = res.data.online;
            this.userIsOnline = res.data.in_room;
          })
          .catch(() => {
            this.loading = false;
            this.userIsInApp = false;
            this.userIsOnline = false;
          });
      }
    },
  },
};
</script>

<style lang="postcss">
</style>
