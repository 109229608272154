var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "align-center relative flex flex-1 flex-col justify-between",
    },
    [
      _c(
        "spinner-overlay",
        {
          staticStyle: { background: "#f7f7f7" },
          attrs: {
            color: "light",
            size: "xl",
            loading: _vm.uiState === "loading",
          },
        },
        [_c("span", { staticClass: "mt-2" }, [_vm._v("Loading chat log ...")])]
      ),
      _vm.error
        ? _c("div", { staticClass: "my-auto bg-red-600 py-4 text-white" }, [
            _c("p", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.error) + " "),
            ]),
          ])
        : _vm._e(),
      !_vm.error
        ? _c("div", { staticClass: "h-full overflow-hidden" }, [
            _c("div", {
              staticClass: "overflow-hidden",
              staticStyle: { height: "115%" },
              attrs: { id: "talkjs-container" },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }