<template>
  <div
    class="align-center flex flex-1 flex-col justify-between bg-fv-black"
    @keydown.esc="setZoomed(false)"
  >
    <div
      v-if="(appointmentHasPassed && !active) || (!active && journal.status)"
      class="flex h-full flex-col items-center justify-center p-5 font-black text-white"
    >
      <p v-if="!Object.keys(archive).length && isCurrentDate">
        The video recording is being processed and will be available shortly.
      </p>

      <p v-if="!Object.keys(archive).length && !isCurrentDate">
        There is no video recording available for this meeting.
      </p>

      <p v-if="archive && archiveStatus === 'expired'">
        The video recording has expired.
      </p>

      <video-archive
        v-if="archive && archiveStatus === 'uploaded'"
        class="h-full w-full"
        :archive="archive"
      />
    </div>

    <div
      v-if="message && !active"
      class="flex h-full flex-col items-center justify-center p-5 font-black text-white"
    >
      <p>
        {{ message }}
      </p>
    </div>

    <div
      v-if="(!appointmentHasPassed && !journal.status) || active"
      class="my-auto w-full bg-fv-black text-white"
      :class="zoomed ? 'h-full' : 'h-half-screen'"
    >
      <stream-session id="videoContainer" class="relative h-full w-full" />
    </div>

    <connection-panel v-if="appointment" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { subMinutes, isBefore, isSameDay } from 'date-fns';
import VideoArchive from './VideoArchive.vue';
import ConnectionPanel from '../interface/ConnectionPanel.vue';
import StreamSession from './StreamSession.vue';

export default {
  components: {
    StreamSession,
    VideoArchive,
    ConnectionPanel,
  },

  data() {
    return {
      appointmentHasPassed: false,
      isCurrentDate: false,
    };
  },

  computed: {
    ...mapState('opentok', ['active', 'zoomed', 'message']),
    ...mapState('journal', ['journal']),
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
      loading: 'getLoading',
      booking: 'getBooking',
      archive: 'getArchive',
    }),
    archiveStatus() {
      /** 'uploaded' || 'expired' */
      if (!this.archive) {
        return null;
      }
      return this.archive.status;
    },
  },

  watch: {
    booking(val) {
      if (val.booking_datetime) {
        this.checkIfAppointmentHasPassed(val);
      }
    },
  },

  mounted() {
    if (this.booking) {
      this.checkIfAppointmentHasPassed(this.booking);
      this.checkIfWithinSameDay(this.booking);
    }
  },

  methods: {
    ...mapActions('appointment', ['generateAppointmentRoom']),
    ...mapMutations('opentok', ['setZoomed']),

    checkIfWithinSameDay(booking) {
      this.isCurrentDate = isSameDay(new Date(booking.dateTime), new Date());
    },

    checkIfAppointmentHasPassed(booking) {
      const start = new Date(booking.dateTime);
      const now = subMinutes(new Date(), 60);
      const hasPassed = isBefore(start, now);

      this.appointmentHasPassed = hasPassed;
      this.renderVideoCall = !hasPassed;
    },
  },
};
</script>
