<template>
  <div class="align-center relative flex flex-1 flex-col justify-between">
    <spinner-overlay
      color="light"
      size="xl"
      :loading="uiState === 'loading'"
      style="background: #f7f7f7"
    >
      <span class="mt-2">Loading chat log ...</span>
    </spinner-overlay>
    <div
      v-if="error"
      class="my-auto bg-red-600 py-4 text-white"
    >
      <p class="text-center">
        {{ error }}
      </p>
    </div>

    <div
      v-if="!error"
      class="h-full overflow-hidden"
    >
      <div
        id="talkjs-container"
        class="overflow-hidden"
        style="height: 115%"
      >
        <!-- <i>Loading chat...</i> -->
      </div>
      <!-- End Chat Container ======================== -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Talk from 'talkjs';
import { ChatApi } from '@/api';
import { TALK_APP_ID } from '@/constants';

export default {
  data() {
    return {
      session: null,
      chatUser: null,
      uiState: 'loading',
      error: '',
      chatUserSignature: null,
      chatUserId: null,
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapState('user', ['user']),
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
      booking: 'getBooking',
    }),
    ...mapGetters('auth', ['isAdmin']),
  },

  async mounted() {
    await this.fetchUserSignature();

    try {
      await Talk.ready;
    } catch (error) {
      console.error(error);
    }

    this.chatUser = new Talk.User({
      id: this.chatUserId,
      name: this.booking.veterinary.display_name,
      role: 'LogUser',
    });

    this.session = new Talk.Session({
      appId: TALK_APP_ID,
      me: this.chatUser,
      signature: this.chatUserSignature,
    });

    this.createChatBox();
  },

  beforeDestroy() {
    if (this.session) {
      this.session.destroy();
    }
  },

  methods: {
    async createChatBox() {
      this.error = '';

      try {
        const { data } = await ChatApi.getConversation(this.appointment.id);

        if (!data.conversation) {
          throw Error(
            `Couldn't find chat associated with appointment #${
              this.appointment.id
            }`
          );
        }
        const chatbox = this.session.createChatbox(data.conversation.id);
        const talkJSContainer = document.getElementById('talkjs-container');
        chatbox.mount(talkJSContainer);
        this.uiState = 'idle';
      } catch (error) {
        this.error = error;
        this.uiState = 'idle';
      }
    },
    async fetchUserSignature() {
      let vetId;
      if (this.isAdmin) {
        vetId = this.booking.user_id;
      }
      const { data } = await ChatApi.getUserSignature(vetId);
      this.chatUserSignature = data.signature;
      this.chatUserId = data.id;
    },
  },
};
</script>
