var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "align-center flex flex-1 flex-col justify-between bg-fv-black",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.setZoomed(false)
        },
      },
    },
    [
      (_vm.appointmentHasPassed && !_vm.active) ||
      (!_vm.active && _vm.journal.status)
        ? _c(
            "div",
            {
              staticClass:
                "flex h-full flex-col items-center justify-center p-5 font-black text-white",
            },
            [
              !Object.keys(_vm.archive).length && _vm.isCurrentDate
                ? _c("p", [
                    _vm._v(
                      " The video recording is being processed and will be available shortly. "
                    ),
                  ])
                : _vm._e(),
              !Object.keys(_vm.archive).length && !_vm.isCurrentDate
                ? _c("p", [
                    _vm._v(
                      " There is no video recording available for this meeting. "
                    ),
                  ])
                : _vm._e(),
              _vm.archive && _vm.archiveStatus === "expired"
                ? _c("p", [_vm._v(" The video recording has expired. ")])
                : _vm._e(),
              _vm.archive && _vm.archiveStatus === "uploaded"
                ? _c("video-archive", {
                    staticClass: "h-full w-full",
                    attrs: { archive: _vm.archive },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.message && !_vm.active
        ? _c(
            "div",
            {
              staticClass:
                "flex h-full flex-col items-center justify-center p-5 font-black text-white",
            },
            [_c("p", [_vm._v(" " + _vm._s(_vm.message) + " ")])]
          )
        : _vm._e(),
      (!_vm.appointmentHasPassed && !_vm.journal.status) || _vm.active
        ? _c(
            "div",
            {
              staticClass: "my-auto w-full bg-fv-black text-white",
              class: _vm.zoomed ? "h-full" : "h-half-screen",
            },
            [
              _c("stream-session", {
                staticClass: "relative h-full w-full",
                attrs: { id: "videoContainer" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.appointment ? _c("connection-panel") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }