<template>
  <div id="session" class="h-full">
    <div v-if="publisherError" class="mx-24 text-center">
      <fv-icon class="mb-4 text-3xl" icon="warning" />
      <p class="mb-6">
        {{ publisherError }}
      </p>
      <a
        v-if="showCamMicHowtoLink"
        class="text-primary underline"
        href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en"
        target="_blank"
      >
        How to use the camera/mic in Chrome
        <font-awesome-icon icon="external-link-alt" />
      </a>
    </div>
    <div v-else-if="!showPublisher" class="h-full text-center">
      <countdown-timer />
    </div>
    <div v-else class="relative flex h-full w-full flex-col">
      <user-connection-status
        v-if="isShowingAnimalOwnerScores"
        class="absolute bottom-16 left-0 z-10"
      />
      <user-connection-status
        v-if="isShowingVetScores"
        class="absolute bottom-16 left-0 z-10"
        :is-for-vet="true"
      />
      <div
        v-if="areScoresReadyToShow()"
        class="absolute bottom-0 z-10 flex select-none flex-row space-x-2 rounded-lg bg-fv-black bg-opacity-50 py-3 px-5 text-xs text-gray-300"
      >
        <button
          class="mr-2 border-r border-gray-800 pr-4"
          @mouseover="setShowAnimalOwnerScores(true)"
          @mouseout="setShowAnimalOwnerScores(false)"
        >
          <div>Customer</div>
          <div class="flex flex-row space-x-2">
            <span>
              <fv-icon :class="animalOwnerAudioScore.color" icon="sound" />
            </span>
            <span>
              <fv-icon :class="animalOwnerVideoScore.color" icon="video" />
            </span>
          </div>
        </button>
        <button
          @mouseover="setShowVetScores(true)"
          @mouseout="setShowVetScores(false)"
        >
          <div>You</div>
          <div class="flex flex-row space-x-2">
            <fv-icon
              :class="vetAudioScore.color + ' svg-inline--fa'"
              icon="sound"
            />
            <fv-icon
              :class="vetVideoScore.color + ' svg-inline--fa'"
              icon="video"
            />
          </div>
        </button>
      </div>
      <subscriber
        v-if="stream"
        class="h-full w-full"
        :stream="stream"
        :session="session"
        @error="errorHandler"
        @subscriberConnected="subscriberConnected"
      />

      <publisher
        :session="session"
        :class="
          animalOwnerOnline
            ? 'absolute bottom-0 right-0 h-1/4 w-1/4'
            : 'h-full w-full'
        "
        @error="publisherErrorHandler"
        @publisherConnected="publisherConnected"
        @publisherStreamCreated="publisherStreamCreated"
        @publisherStreamDestroyed="publisherStreamDestroyed"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import UserConnectionStatus from '@/components/interface/UserConnectionStatus';
import {
  calculateVideoScore,
  calculateAudioScore,
  rateMosScore,
} from '@/utils/helpers/connection-stats-helpers';
import Publisher from './Publisher.vue';
import Subscriber from './Subscriber.vue';
import CountdownTimer from './CountdownTimer.vue';

export default {
  name: 'Session',
  components: {
    Publisher,
    Subscriber,
    CountdownTimer,
    UserConnectionStatus,
  },
  data: () => ({
    isSessionConnected: false,
    publish: false,
    micEnabled: true,
    videoEnabled: true,
    publisherError: '',
    showCamMicHowtoLink: false,
    getStatsInterval: null,
    publisher: null,
    subscriberStats: [],
    publisherStats: [],
    isShowingVetScores: false,
    isShowingAnimalOwnerScores: false,
  }),
  computed: {
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
    }),

    ...mapGetters('opentok', {
      vetVideoScore: 'getVetVideoScore',
      vetAudioScore: 'getVetAudioScore',
      animalOwnerVideoScore: 'getAnimalOwnerVideoScore',
      animalOwnerAudioScore: 'getAnimalOwnerAudioScore',
    }),

    ...mapState('opentok', [
      'stream',
      'session',
      'subscriber',
      'animalOwnerOnline',
      'vetOnline',
      'vetOffline',
      'active',
      'activating',
      'showPublisher',
    ]),
  },

  beforeDestroy() {
    this.stopGetStats();
  },

  mounted() {
    clearInterval(this.getStatsInterval);
  },

  methods: {
    ...mapActions('opentok', [
      'setVetOnline',
      'setVetOffline',
      'setAnimalOwnerOnline',
      'setActive',
      'setActivating',
      'setAnimalOwnerAudioScore',
      'setAnimalOwnerVideoScore',
      'setVetAudioScore',
      'setVetVideoScore',
    ]),

    ...mapMutations('opentok', ['setPublisher', 'setSubscriber']),

    setShowAnimalOwnerScores(isShowing) {
      this.isShowingAnimalOwnerScores = isShowing;
    },

    setShowVetScores(isShowing) {
      this.isShowingVetScores = isShowing;
    },

    areScoresReadyToShow() {
      function isValueSet(obj) {
        // eslint-disable-next-line
        return obj && !isNaN(obj.value);
      }

      const readyToShow =
        isValueSet(this.vetAudioScore) &&
        isValueSet(this.vetAudioScore) &&
        isValueSet(this.animalOwnerVideoScore) &&
        isValueSet(this.animalOwnerAudioScore);

      return readyToShow;
    },
    publisherErrorHandler(err) {
      this.publisherError = err.message;
      this.showCamMicHowtoLink = err.name === 'OT_USER_MEDIA_ACCESS_DENIED';
    },

    errorHandler(err) {
      this.$notify({
        group: 'error',
        title: 'Something went wrong',
        text: err.message,
        closeOnClick: true,
        ignoreDuplicates: true,
      });
    },

    // Publisher (vet)
    publisherConnected(publisher) {
      this.setPublisher(publisher);
      this.publisher = publisher;
    },
    publisherStreamCreated() {
      this.setActive(true);
      this.setActivating(false);
      this.setVetOnline({ appointment_id: this.appointment.id });
    },
    publisherStreamDestroyed() {
      this.setActive(false);
      this.setActivating(false);

      if (this.vetOnline) {
        this.setVetOffline({ appointment_id: this.appointment.id });
      }
    },

    // Subscriber (animal owner)
    subscriberConnected(subscriber) {
      clearInterval(this.getStatsInterval);
      this.setSubscriber(subscriber);
      this.getStats();
      this.getStatsInterval = setInterval(this.getStats, 5000);
    },

    getStats() {
      if (this.subscriber && this.subscriber.stream) {
        this.subscriber.getStats((error, subscriberStats) => {
          if (error) {
            console.error(
              'Error getting subscriber subscriberStats. ',
              error.message
            );
            return;
          }

          this.subscriberStats.push(subscriberStats);

          if (this.subscriberStats.length >= 2) {
            const { mos: videoMosScore } = calculateVideoScore(
              this.subscriber,
              this.subscriberStats
            );

            this.setAnimalOwnerVideoScore(rateMosScore(videoMosScore));
            const audioMosScore = calculateAudioScore(this.subscriberStats);
            this.setAnimalOwnerAudioScore(rateMosScore(audioMosScore));
          }
        });
      }

      if (this.publisher && this.publisher.stream) {
        this.publisher.getStats((error, publisherStats) => {
          if (error) {
            console.error(
              'Error getting publisher publisherStats. ',
              error.message
            );
            return;
          }

          const publisherStatsObj = publisherStats[0].stats;

          this.publisherStats.push(publisherStatsObj);

          if (this.publisherStats.length >= 2) {
            const { mos: videoMosScore } = calculateVideoScore(
              this.publisher,
              this.publisherStats
            );

            this.setVetVideoScore(rateMosScore(videoMosScore));
            const audioMosScore = calculateAudioScore(this.publisherStats);
            this.setVetAudioScore(rateMosScore(audioMosScore));
          }
        });
      }
    },

    stopGetStats() {
      if (this.getStatsInterval) {
        clearInterval(this.getStatsInterval);
        this.subscriberStats = [];
        this.setAnimalOwnerAudioScore(null);
        this.setAnimalOwnerVideoScore(null);
        this.setVetVideoScore(null);
        this.setVetAudioScore(null);
      }
    },
  },
};
</script>

<style lang="postcss">
.bottom-16 {
  bottom: 4em;
}
.OT_widget-container {
  @apply bg-fv-black;
}
</style>
