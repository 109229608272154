<template>
  <div id="room" class="relative flex h-screen flex-wrap overflow-hidden pt-10">
    <div
      v-if="appointmentLoading"
      class="absolute inset-0 flex flex-col items-center justify-center bg-beigeLighter"
    >
      <div class="select-none">
        <img
          class="mx-auto my-auto h-64 w-56"
          :src="require('@/assets/svg/preparing_room.svg')"
        />
        <p class="mt-4 inline-flex items-center space-x-2">
          <span class="font-display text-lg tracking-wide"
            >Preparing consultation room..</span
          >
          <base-spinner class="text-xl" :loading="true" />
        </p>
      </div>
    </div>
    <template v-if="appointment && !appointmentLoading" class="min-h-screen">
      <div
        class="flex h-screen flex-col overflow-hidden"
        :class="zoomed ? 'w-full' : 'w-1/2'"
      >
        <VideoWindow
          v-if="serviceChannel === 'video'"
          :style="
            zoomed
              ? 'min-height: 100vh; padding-bottom: 2.5rem;'
              : 'min-height:60vh;'
          "
          class="mx-2 flex flex-shrink-0 overflow-auto border"
          tabindex="0"
        />
        <ChatLogWindow
          v-if="serviceChannel === 'chat'"
          :style="{ minHeight: '60vh', maxHeight: '60vh' }"
          class="flex flex-shrink-0 overflow-auto px-2"
          tabindex="0"
        />

        <InfoBox class="mx-2 mb-2 mt-2 flex border-r border-l pb-2" />
      </div>
      <PanelArea class="flex-1 border-l" />
    </template>

    <div
      v-if="clinicMapIsOpen"
      class="absolute top-0 left-0 right-0 bottom-0 z-10 flex justify-center bg-smoke-light"
    >
      <div class="map m-auto flex items-center overflow-hidden rounded">
        <clinic-map class="bg-white" :country="user.country_id || 826" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import PanelArea from '@/components/interface/PanelArea.vue';
import InfoBox from '@/components/interface/InfoBox.vue';
import VideoWindow from '@/components/video/VideoWindow.vue';
import ClinicMap from '@/components/clinic-map/ClinicMap.vue';
import ChatLogWindow from '@/components/chat/ChatLogWindow.vue';
import { cleanAppointmentsProperties } from '@/plugins/analytics/appointment';
import { APPOINTMENT_STATUS } from '@/utils/schedule-utils';

export default {
  components: {
    VideoWindow,
    PanelArea,
    InfoBox,
    ClinicMap,
    ChatLogWindow,
  },

  data() {
    return {
      error: '',
      appointmentInterval: null,
    };
  },

  computed: {
    ...mapState('appointment', {
      appointment: 'appointment',
      appointmentLoading: 'loading',
    }),
    ...mapState('user', ['user']),
    ...mapState('opentok', ['zoomed']),
    ...mapState('animal', { animalLoading: 'loading' }),
    ...mapState('clinicMap', {
      clinicMapIsOpen: 'isOpen',
    }),
    ...mapGetters({ locale: 'getLocale' }),
    ...mapGetters('appointment', {
      serviceChannel: 'getServiceChannel',
    }),
  },

  async beforeMount() {
    await this.fetchAppointment(this.$route.params.id);
    this.appointmentViewedEvent(this.appointment);

    if (this.appointment.status === APPOINTMENT_STATUS.pending) {
      this.pollAppointment();
    }
  },

  created() {
    this.stopPublishing();
    this.resetSessionState();
  },

  beforeDestroy() {
    this.stopPublishing();
    clearInterval(this.appointmentInterval);
  },

  methods: {
    ...mapActions('appointment', ['fetchAppointment']),
    ...mapActions('opentok', ['stopPublishing']),
    ...mapMutations('opentok', ['resetSessionState']),

    async appointmentViewedEvent(appointment) {
      this.$gtm.trackEvent({
        event: 'appointment_viewed',
        appointment: cleanAppointmentsProperties(appointment),
      });
    },

    pollAppointment() {
      this.appointmentInterval = setInterval(async () => {
        await this.fetchAppointmentsInterval();
      }, 1000 * 60);
    },

    async fetchAppointmentsInterval() {
      await this.fetchAppointment(this.$route.params.id);

      if (this.appointment.status !== APPOINTMENT_STATUS.pending) {
        clearInterval(this.appointmentInterval);
      }
    },
  },
};
</script>

<style lang="scss">
/* purgecss start ignore */
.map {
  width: 75vw;
  height: 90vh;

  .copy-button {
    color: white;
    background: #00bed6;
    border-radius: 0.25rem;
    font-size: 1rem;

    &:hover {
      background: #00a7bd;
    }
  }

  .referral-button {
    background: #00bed6;
    color: white;
    padding: 0.5rem;

    &:hover {
      background: #00a7bd;
    }
  }
}
/* purgecss end ignore */
</style>
