<template>
  <div />
</template>

<script>
import OT from '@opentok/client';

export default {
  name: 'Publisher',
  props: {
    session: {
      type: OT.Session,
      required: false,
      default: null,
    },
    opts: {
      type: Object,
      required: false,
      default() {
        return {
          insertMode: 'append',
          width: '100%',
          height: '100%',
          showControls: false,
          fitMode: 'contain',
          // style: {
          //   audioLevelDisplayMode: 'on',
          // },
        };
      },
    },
  },
  data() {
    return {
      publisher: null,
    };
  },
  mounted() {
    this.publisher = OT.initPublisher(this.$el, this.opts, err => {
      if (err) {
        this.handleError(err);
      } else {
        this.$emit('publisherCompleted');
      }
    }).on({
      streamCreated: () => {
        this.$emit('publisherStreamCreated');
      },
      streamDestroyed: event => {
        this.$emit('publisherStreamDestroyed', event.reason);
      },
    });

    this.$emit('publisherCreated', this.publisher);

    const publish = () => {
      this.session.publish(this.publisher, err => {
        if (err) {
          this.handleError(err);
        } else {
          this.$emit('publisherConnected', this.publisher);
        }
      });
    };

    if (this.session && this.session.isConnected()) {
      publish();
    }

    if (this.session) {
      this.session.on('sessionConnected', publish);
    }
  },

  methods: {
    handleError(err) {
      const message = this.getErrorMessage(err);
      const { name } = err;
      this.$emit('error', { message, name });

      this.publisher.destroy();
      this.publisher = null;
    },

    getErrorMessage(err) {
      switch (err.name) {
        case 'OT_USER_MEDIA_ACCESS_DENIED':
          return 'Please allow access to the camera and microphone and reload the page.';
        case 'OT_NOT_CONNECTED':
          return 'Could not start video call. You are not connected to the internet.';
        case 'OT_CREATE_PEER_CONNECTION_FAILED':
          return 'Could not start video call. This could be due to a restrictive firewall.';
        default:
          return 'An unknown error occurred while trying to start the video call. Please try again later.';
      }
    },
  },
};
</script>
