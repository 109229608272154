export default {
  cleanAnimalObj: animal => ({
    id: animal.id,
    name: animal.name,
    status: animal.status,
    race: animal.race,
    animal_type: animal.animal_type,
    birthdate: animal.birthdate,
    gender: animal.gender,
    height: animal.height,
    insurance_id: animal.insurance_id,
    insurance_name: animal.insurance_type?.name,
  }),
};
