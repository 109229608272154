var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative flex h-screen flex-wrap overflow-hidden pt-10",
      attrs: { id: "room" },
    },
    [
      _vm.appointmentLoading
        ? _c(
            "div",
            {
              staticClass:
                "absolute inset-0 flex flex-col items-center justify-center bg-beigeLighter",
            },
            [
              _c("div", { staticClass: "select-none" }, [
                _c("img", {
                  staticClass: "mx-auto my-auto h-64 w-56",
                  attrs: { src: require("@/assets/svg/preparing_room.svg") },
                }),
                _c(
                  "p",
                  { staticClass: "mt-4 inline-flex items-center space-x-2" },
                  [
                    _c(
                      "span",
                      { staticClass: "font-display text-lg tracking-wide" },
                      [_vm._v("Preparing consultation room..")]
                    ),
                    _c("base-spinner", {
                      staticClass: "text-xl",
                      attrs: { loading: true },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.appointment && !_vm.appointmentLoading
        ? [
            _c(
              "div",
              {
                staticClass: "flex h-screen flex-col overflow-hidden",
                class: _vm.zoomed ? "w-full" : "w-1/2",
              },
              [
                _vm.serviceChannel === "video"
                  ? _c("VideoWindow", {
                      staticClass:
                        "mx-2 flex flex-shrink-0 overflow-auto border",
                      style: _vm.zoomed
                        ? "min-height: 100vh; padding-bottom: 2.5rem;"
                        : "min-height:60vh;",
                      attrs: { tabindex: "0" },
                    })
                  : _vm._e(),
                _vm.serviceChannel === "chat"
                  ? _c("ChatLogWindow", {
                      staticClass: "flex flex-shrink-0 overflow-auto px-2",
                      style: { minHeight: "60vh", maxHeight: "60vh" },
                      attrs: { tabindex: "0" },
                    })
                  : _vm._e(),
                _c("InfoBox", {
                  staticClass: "mx-2 mb-2 mt-2 flex border-r border-l pb-2",
                }),
              ],
              1
            ),
            _c("PanelArea", { staticClass: "flex-1 border-l" }),
          ]
        : _vm._e(),
      _vm.clinicMapIsOpen
        ? _c(
            "div",
            {
              staticClass:
                "absolute top-0 left-0 right-0 bottom-0 z-10 flex justify-center bg-smoke-light",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "map m-auto flex items-center overflow-hidden rounded",
                },
                [
                  _c("clinic-map", {
                    staticClass: "bg-white",
                    attrs: { country: _vm.user.country_id || 826 },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }