import animalAnalytics from './animal';

const cleanBookingObj = booking => ({
  id: booking.id,
  booking_datetime: booking.booking_datetime,
  date_time: booking.dateTime,
  country_id: booking.country_id,
  digital_clinic_id: booking.digital_clinic_id,
  service: booking.service,
  slot: booking.slot,
  timezone: booking.timezone,
  user_id: booking.user_id,
  user_service_id: booking.user_service_id,
});

const cleanServiceType = serviceType => ({
  id: serviceType.id,
  channel: serviceType.channel,
  key: serviceType.key,
  name: serviceType.name,
  status: serviceType.status,
});

export const cleanAppointmentsProperties = appointment => ({
  id: appointment.id,
  customer_id: appointment.customer_id,
  animal: appointment.animal
    ? animalAnalytics.cleanAnimalObj(appointment.animal)
    : null,
  booking: appointment.booking ? cleanBookingObj(appointment.booking) : null,
  service_id: appointment.service_id,
  booking_id: appointment.booking_id,
  case_type_id: appointment.case_type_id,
  service_type: appointment.service_type
    ? cleanServiceType(appointment.service_type)
    : null,
  status: appointment.status,
  promo_code: appointment.promo_code,
  order_date: appointment.order_date,
  meeting_type: appointment.meeting_type,
  journal_status: appointment.journal_status,
  insurance_id: appointment.insurance_id,
  insurance_name: appointment.insurance_type?.name,
  device: appointment.device,
  weight: appointment.weight,
  weight_unit: appointment.weight_unit,
  created_at: appointment.created_at,
  problem_duration: appointment.problem_duration,
  problem_earlier: appointment.problem_earlier,
});

const cleanParticipantsObjProps = participants =>
  Object.keys(participants).map(user_id => ({
    user_id,
    access: participants[user_id].access,
    notify: participants[user_id].notify,
  }));

export const cleanChatConversationsProperties = conversation => ({
  id: conversation.id,
  appointment_id: conversation.custom.appointmentId,
  user_first_joined: conversation.custom.userFirstJoined,
  user_last_joined: conversation.custom.userLastJoined,
  conversation_is_active: conversation.conversationIsActive,
  conversation_is_open: conversation.conversationIsOpen,
  created_at: conversation.createdAt,
  participants: cleanParticipantsObjProps(conversation.participants),
});
