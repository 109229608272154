var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTime
    ? _c(
        "div",
        {
          staticClass:
            "ml-auto flex select-none items-center justify-end space-x-1 text-sm text-gray-300",
        },
        [
          _c(
            "span",
            { class: { "text-fv-red": _vm.hasSurpassedSlotDuration } },
            [_vm._v(_vm._s(_vm.time))]
          ),
          _c("fv-icon", {
            staticClass: "ml-2",
            class: { "text-fv-red": _vm.hasSurpassedSlotDuration },
            attrs: { size: "xs", icon: "clock" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }