<template>
  <div class="flex h-full">
    <transition name="fade">
      <video
        v-if="cannedPolicy"
        width="100%"
        height="100%"
        controls
        class="focus:outline-none"
      >
        <source
          :src="cannedPolicy"
          type="video/mp4"
        >
        Your browser does not support the video tag.
      </video>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    archive: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      cannedPolicy: null,
    };
  },

  computed: {
    ...mapState('appointment', ['appointment']),
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapActions('opentok', ['getArchiveStream']),

    getData() {
      this.cannedPolicy = null;

      if (this.archive.status === 'uploaded') {
        this.loading = true;
        this.getArchiveStream(this.appointment.id)
          .then(res => {
            this.cannedPolicy = res.data.canned_policy;
            this.loading = false;
          })
          .catch(e => {
            console.error(e);
          });
      }
    },
  },
};
</script>

<style lang="postcss">
</style>
