var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex h-full" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.cannedPolicy
          ? _c(
              "video",
              {
                staticClass: "focus:outline-none",
                attrs: { width: "100%", height: "100%", controls: "" },
              },
              [
                _c("source", {
                  attrs: { src: _vm.cannedPolicy, type: "video/mp4" },
                }),
                _vm._v(" Your browser does not support the video tag. "),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }