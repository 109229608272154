<template>
  <div class="inline-flex items-center space-x-2">
    <transition name="fade">
      <p v-if="nexmoButtonText" class="text-white">
        {{ nexmoButtonText }}
      </p>
    </transition>
    <transition name="fade">
      <p v-if="error" class="text-red-300">
        {{ error }}
      </p>
    </transition>

    <call-button
      v-show="!videoCallActive"
      :call-status="connectionStatus"
      :disabled="disableIPCalls || !userNumber || videoCallActive || loading"
      :loading="loading"
      type="IP"
      @start-call="callUser()"
      @hang-up="hangUp()"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NexmoClient from 'nexmo-client';
import PhoneNumber from 'awesome-phonenumber';
import { capitalize } from 'lodash';
import { __DEV__ } from '@/constants';
import CallButton, { CALL_STATUSES } from '@/UI/buttons/CallButton';

export default {
  components: { CallButton },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      client: null,
      disableIPCalls: false,
      loading: false,
      currentCall: null,
      callStatus: null,
      error: null,
      nexmoButtonText: '',
      userNumber: '',
      application: null,
      HANGUP_STATUSES: [
        'busy',
        'cancelled',
        'failed',
        'rejected',
        'timeout',
        'machine',
        'unanswered',
      ],
      SUCCESS_STATUSES: ['started', 'ringing', 'answered', 'completed'],
    };
  },
  computed: {
    ...mapGetters('opentok', { videoCallActive: 'getActiveOrActivating' }),
    ...mapState('opentok', ['session']),
    connectionStatus() {
      if (Object.keys(CALL_STATUSES).includes(this.callStatus)) {
        return this.callStatus;
      }

      if (this.callStatus === 'started' || this.callStatus === 'ringing') {
        return CALL_STATUSES.connecting;
      }

      if (this.HANGUP_STATUSES.includes(this.callStatus)) {
        return CALL_STATUSES.disconnected;
      }

      return CALL_STATUSES.noCall;
    },
  },

  mounted() {
    this.initClient();
    this.setNumber();
    this.initNexmo();
  },

  beforeDestroy() {
    if (this.client) {
      this.client.logout();
    }
  },

  methods: {
    initClient() {
      this.client = new NexmoClient();
    },

    async initNexmo() {
      try {
        this.loading = true;
        if (__DEV__) {
          throw Error('IP-calls are disabled in development enviroments');
        }
        if (!this.application) {
          const { data } = await this.$axios.get('/api/nexmo/generate-jwt', {
            params: { appointmentId: this.$route.params.id },
          });
          this.application = await this.client.login(data.jwt);
          this.addEventListeners(this.application);
        }

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: 'error',
          message:
            'An error occured while connecting to the IP-call server. IP-calls are disabled for this consultation.',
        });
        this.disableIPCalls = true;
        this.error = error.message;
        this.loading = false;
        setTimeout(() => {
          this.error = '';
        }, 6000);
      }
    },

    async callUser() {
      try {
        if (!this.application) {
          await this.initNexmo();
        }
        if (!this.userNumber) {
          throw new Error('The customer has provided an invalid phone number');
        }
        this.application.callServer(this.userNumber);
      } catch (error) {
        this.error = error.message;
        setTimeout(() => {
          this.error = '';
        }, 4000);
      }
    },

    async hangUp() {
      if (this.currentCall) {
        const hangup = await this.currentCall.hangUp();
        this.currentCall = null;
        this.callStatus = null;
        return hangup;
      }

      return false;
    },

    setNumber() {
      const number = this.parseNumber();
      this.userNumber = number.getNumber();
    },

    parseNumber() {
      let number = '';
      if (this.phoneNumber.includes('+')) {
        number = new PhoneNumber(this.phoneNumber);
      } else {
        number = new PhoneNumber(this.phoneNumber, this.locale);
      }
      if (!number.isValid() && !number.isMobile()) {
        throw Error('The customer has provided an invalid phone number');
      }
      return number;
    },

    addEventListeners(application) {
      application
        .on('member:call', (member, call) => {
          this.currentCall = call;
          this.nexmoButtonText = call.status;
        })

        .on('call:status:changed', call => {
          this.callStatus = call.status;
          this.nexmoButtonText = capitalize(call.status);
          if (this.HANGUP_STATUSES.includes(call.status)) {
            setTimeout(() => {
              this.currentCall = null;
              this.callStatus = call.status;
              this.nexmoButtonText = '';
            }, 2500);
          }
          if (call.status === 'completed') {
            setTimeout(() => {
              this.currentCall = null;
              this.nexmoButtonText = '';
            }, 1000);
          }
          if (call.status === 'answered') {
            setTimeout(() => {
              this.nexmoButtonText = '';
            }, 1000);
          }
        });
    },
  },
};
</script>
