var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-gray-300" }, [
    _c("div", { staticClass: "select-none text-sm" }, [
      _c("span", [_vm._v("Customer: ")]),
      _vm.userConnected
        ? _c("span", { staticClass: "font-semibold" }, [_vm._v(" Connected ")])
        : _vm.userIsInApp
        ? _c("span", { staticClass: "font-semibold" }, [
            _vm._v(" Outside room "),
          ])
        : _c("span", { staticClass: "font-semibold" }, [_vm._v(" Offline ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }