<template>
  <div
    v-if="showTime"
    class="ml-auto flex select-none items-center justify-end space-x-1 text-sm text-gray-300"
  >
    <span :class="{ 'text-fv-red': hasSurpassedSlotDuration }">{{ time }}</span>
    <fv-icon
      size="xs"
      class="ml-2"
      icon="clock"
      :class="{ 'text-fv-red': hasSurpassedSlotDuration }"
    />
  </div>
</template>

<script>
import {
  differenceInMilliseconds,
  format,
  getMinutes,
  isAfter,
} from 'date-fns';

export default {
  props: {
    startTime: {
      type: String,
      default: '',
    },
    slotDuration: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      nowInterval: null,
      now: new Date(),
      surpassedTenMinutes: false,
    };
  },

  computed: {
    meetingHasStarted() {
      return isAfter(new Date(this.now), new Date(this.startTime));
    },

    time() {
      return (
        (this.meetingHasStarted ? '' : '-') +
        format(new Date(this.diff), 'mm:ss')
      );
    },

    diff() {
      if (this.meetingHasStarted) {
        return differenceInMilliseconds(
          new Date(this.now),
          new Date(this.startTime)
        );
      }
      return differenceInMilliseconds(
        new Date(this.startTime),
        new Date(this.now)
      );
    },

    hasSurpassedSlotDuration() {
      return (
        this.meetingHasStarted &&
        getMinutes(new Date(this.diff)) >= this.slotDuration
      );
    },

    showTime() {
      return (
        format(new Date(this.startTime), 'yyyy-MM-dd') ===
          format(new Date(this.now), 'yyyy-MM-dd') &&
        getMinutes(new Date(this.diff)) <
          (this.meetingHasStarted ? 60 : this.slotDuration)
      );
    },
  },

  created() {
    this.nowInterval = setInterval(() => {
      this.now = new Date();
    }, 1000);
  },

  beforeDestroy() {
    if (this.nowInterval) {
      clearInterval(this.nowInterval);
    }
  },
};
</script>
