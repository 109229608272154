var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inline-flex items-center space-x-2" },
    [
      _c(
        "base-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.callStatus === _vm.CALL_STATUSES.answered,
              expression: "callStatus === CALL_STATUSES.answered",
            },
          ],
          staticClass: "width-1/3 mr-auto",
          attrs: {
            id: _vm.type + "-hangup-btn",
            tooltip: "Click to end call",
            loading: _vm.loading,
            disabled: _vm.disabled || _vm.loading,
            color: "danger",
          },
          on: {
            click: function ($event) {
              return _vm.endCall()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "relative inline-flex items-start space-x-2" },
            [
              _c("spinner-overlay", {
                attrs: { size: "xs", loading: _vm.loading },
              }),
              _c("fv-icon", { attrs: { icon: "end-call-40" } }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass:
                "relative ml-2 inline-flex items-end space-x-2 font-bold",
            },
            [_vm._v(" Hang up ")]
          ),
        ]
      ),
      _c(
        "base-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.callStatus !== _vm.CALL_STATUSES.answered,
              expression: "callStatus !== CALL_STATUSES.answered",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                content: !_vm.disabled
                  ? _vm.tooltipText
                  : _vm.type + "-calls are disabled for this consultation.",
              },
              expression:
                "{\n      content: !disabled\n        ? tooltipText\n        : `${type}-calls are disabled for this consultation.`,\n    }",
            },
          ],
          attrs: {
            id: _vm.type + "-call-btn",
            loading: _vm.loading,
            disabled: _vm.disabled || _vm.loading,
            color: "light",
          },
          on: {
            click: function ($event) {
              _vm.callStatus === _vm.CALL_STATUSES.connecting
                ? _vm.endCall()
                : _vm.startCall()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "relative inline-flex items-start space-x-2" },
            [
              _c("spinner-overlay", {
                attrs: { size: "xs", loading: _vm.loading },
              }),
              _c("fv-icon", {
                class: {
                  "animate-bounce":
                    _vm.callStatus === _vm.CALL_STATUSES.connecting,
                  "text-navy": _vm.callStatus === _vm.CALL_STATUSES.connecting,
                },
                attrs: { icon: _vm.icons[_vm.type] },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass:
                "relative ml-2 inline-flex items-end space-x-2 font-bold",
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          ),
          _vm.vonageCleanup && _vm.countdown > 0
            ? _c("span", [_vm._v(" " + _vm._s(_vm.countdown) + "s ")])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }