var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full", attrs: { id: "session" } }, [
    _vm.publisherError
      ? _c(
          "div",
          { staticClass: "mx-24 text-center" },
          [
            _c("fv-icon", {
              staticClass: "mb-4 text-3xl",
              attrs: { icon: "warning" },
            }),
            _c("p", { staticClass: "mb-6" }, [
              _vm._v(" " + _vm._s(_vm.publisherError) + " "),
            ]),
            _vm.showCamMicHowtoLink
              ? _c(
                  "a",
                  {
                    staticClass: "text-primary underline",
                    attrs: {
                      href: "https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(" How to use the camera/mic in Chrome "),
                    _c("font-awesome-icon", {
                      attrs: { icon: "external-link-alt" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : !_vm.showPublisher
      ? _c(
          "div",
          { staticClass: "h-full text-center" },
          [_c("countdown-timer")],
          1
        )
      : _c(
          "div",
          { staticClass: "relative flex h-full w-full flex-col" },
          [
            _vm.isShowingAnimalOwnerScores
              ? _c("user-connection-status", {
                  staticClass: "absolute bottom-16 left-0 z-10",
                })
              : _vm._e(),
            _vm.isShowingVetScores
              ? _c("user-connection-status", {
                  staticClass: "absolute bottom-16 left-0 z-10",
                  attrs: { "is-for-vet": true },
                })
              : _vm._e(),
            _vm.areScoresReadyToShow()
              ? _c(
                  "div",
                  {
                    staticClass:
                      "absolute bottom-0 z-10 flex select-none flex-row space-x-2 rounded-lg bg-fv-black bg-opacity-50 py-3 px-5 text-xs text-gray-300",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "mr-2 border-r border-gray-800 pr-4",
                        on: {
                          mouseover: function ($event) {
                            return _vm.setShowAnimalOwnerScores(true)
                          },
                          mouseout: function ($event) {
                            return _vm.setShowAnimalOwnerScores(false)
                          },
                        },
                      },
                      [
                        _c("div", [_vm._v("Customer")]),
                        _c("div", { staticClass: "flex flex-row space-x-2" }, [
                          _c(
                            "span",
                            [
                              _c("fv-icon", {
                                class: _vm.animalOwnerAudioScore.color,
                                attrs: { icon: "sound" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            [
                              _c("fv-icon", {
                                class: _vm.animalOwnerVideoScore.color,
                                attrs: { icon: "video" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        on: {
                          mouseover: function ($event) {
                            return _vm.setShowVetScores(true)
                          },
                          mouseout: function ($event) {
                            return _vm.setShowVetScores(false)
                          },
                        },
                      },
                      [
                        _c("div", [_vm._v("You")]),
                        _c(
                          "div",
                          { staticClass: "flex flex-row space-x-2" },
                          [
                            _c("fv-icon", {
                              class:
                                _vm.vetAudioScore.color + " svg-inline--fa",
                              attrs: { icon: "sound" },
                            }),
                            _c("fv-icon", {
                              class:
                                _vm.vetVideoScore.color + " svg-inline--fa",
                              attrs: { icon: "video" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.stream
              ? _c("subscriber", {
                  staticClass: "h-full w-full",
                  attrs: { stream: _vm.stream, session: _vm.session },
                  on: {
                    error: _vm.errorHandler,
                    subscriberConnected: _vm.subscriberConnected,
                  },
                })
              : _vm._e(),
            _c("publisher", {
              class: _vm.animalOwnerOnline
                ? "absolute bottom-0 right-0 h-1/4 w-1/4"
                : "h-full w-full",
              attrs: { session: _vm.session },
              on: {
                error: _vm.publisherErrorHandler,
                publisherConnected: _vm.publisherConnected,
                publisherStreamCreated: _vm.publisherStreamCreated,
                publisherStreamDestroyed: _vm.publisherStreamDestroyed,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }