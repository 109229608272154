var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-t border-gray-800 py-3 px-5" }, [
    _c("div", { staticClass: "flex items-center justify-between space-x-2" }, [
      _c(
        "div",
        { staticClass: "flex items-center space-x-2" },
        [
          _vm.connected
            ? _c(
                "base-button",
                { on: { click: _vm.toggleMic } },
                [
                  _c("fv-icon", {
                    class: { " text-fv-red": !_vm.micEnabled },
                    attrs: {
                      icon: _vm.micEnabled
                        ? "microphone"
                        : "microphone-disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.connected
            ? _c(
                "base-button",
                { on: { click: _vm.toggleCam } },
                [
                  _c("fv-icon", {
                    class: { " text-fv-red": !_vm.videoEnabled },
                    attrs: {
                      icon: _vm.videoEnabled ? "video" : "video-disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.connected || _vm.zoomed
            ? _c(
                "base-button",
                {
                  on: {
                    click: function ($event) {
                      ;(_vm.zoomedValue = !_vm.zoomed),
                        _vm.setZoomed(_vm.zoomedValue)
                    },
                  },
                },
                [
                  _c("fv-icon", {
                    attrs: {
                      "fixed-width": "",
                      icon: _vm.zoomed ? "exit-fullscreen" : "fullscreen",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.journal.status && !(_vm.journal.status && _vm.ended)
            ? _c("call-button", {
                attrs: {
                  type: "video",
                  "call-status": _vm.callStatus,
                  description: _vm.message,
                  loading: _vm.activating,
                  disabled:
                    _vm.vonageCleanup ||
                    _vm.activating ||
                    !_vm.appointment.id ||
                    _vm.appointment.status === _vm.APPOINTMENT_STATUS.pending,
                  active: _vm.active,
                  "vonage-cleanup": _vm.vonageCleanup,
                },
                on: {
                  "start-call": _vm.startSession,
                  "hang-up": _vm.vetHangUp,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.active
        ? _c(
            "div",
            [
              _vm.appointment.user
                ? _c("NexmoIPCallButton", {
                    attrs: {
                      "phone-number": _vm.appointment.user
                        .mobile_phone_formatted
                        ? _vm.appointment.user.mobile_phone_formatted
                        : _vm.appointment.user.phone,
                      locale: _vm.appointment.user.country.iso_3166_2,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.active
        ? _c(
            "div",
            { staticClass: "inline-flex flex-col space-y-1 text-white" },
            [
              _vm.appointment.booking
                ? _c("meeting-timer", {
                    attrs: {
                      "start-time": _vm.appointment.booking.booking_datetime,
                      "slot-duration": _vm.appointment.booking.slot.duration,
                    },
                  })
                : _vm._e(),
              _c("online-status-check", {
                staticClass: "flex items-center text-right",
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }