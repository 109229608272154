var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.audioScore || _vm.videoScore
    ? _c(
        "div",
        {
          staticClass:
            "flex flex-row space-x-2 bg-fv-black rounded-lg bg-opacity-50 text-gray-300 py-3 px-5 text-xs select-none",
        },
        [
          _vm.audioScore
            ? _c(
                "div",
                { staticClass: "items-center inline-flex space-x-1" },
                [
                  _c("fv-icon", {
                    class: _vm.audioScore.color + " svg-inline--fa",
                    attrs: { icon: "sound" },
                  }),
                  _vm.audioScore
                    ? _c("span", { staticClass: "text-gray-dark" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.audioScore.text) +
                            " (" +
                            _vm._s(
                              _vm.audioScore.value ? _vm.audioScore.value : ""
                            ) +
                            ") "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.videoScore
            ? _c(
                "div",
                { staticClass: "items-center inline-flex space-x-1" },
                [
                  _c("fv-icon", {
                    class: _vm.videoScore.color + " svg-inline--fa",
                    attrs: { icon: "video" },
                  }),
                  _vm.videoScore
                    ? _c("span", { staticClass: "text-gray-dark" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.videoScore.text) +
                            " (" +
                            _vm._s(
                              _vm.videoScore.value ? _vm.videoScore.value : ""
                            ) +
                            ") "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }