var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment && _vm.appointment.booking
    ? _c(
        "div",
        {
          staticClass:
            "mb-8 flex flex-col overflow-auto border-t bg-white bg-opacity-75 text-navy shadow-md",
          attrs: { id: "infoBox" },
        },
        [
          _vm.journalIsSigned
            ? _c(
                "div",
                {
                  staticClass:
                    "w-full bg-fv-green px-5 py-4 font-semibold text-white",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center space-x-3" },
                    [
                      _c("fv-icon", { attrs: { icon: "check-mark-alt" } }),
                      _c("span", { staticClass: "text-sm" }, [
                        _vm._v(
                          " Journal signed. " +
                            _vm._s(
                              _vm.journal.notified
                                ? "Email was delivered at " +
                                    _vm.journal.notified
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "infoBox grid grid-flow-row grid-cols-2 px-5 py-5 leading-normal",
            },
            [
              _c(
                "div",
                [
                  _vm.animal && _vm.animal.name
                    ? _c(
                        "display-row",
                        { staticClass: "justify-items-center font-semibold" },
                        [_vm._v(" " + _vm._s(_vm.animal.name) + " ")]
                      )
                    : _vm._e(),
                  _c("display-row", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.animal.animal_type
                            ? _vm.animal.animal_type.name
                            : ""
                        ) +
                        " " +
                        _vm._s(_vm.animal.race ? " - " + _vm.animal.race : "") +
                        " "
                    ),
                  ]),
                  _c("display-row", [
                    _vm.animal && _vm.animal.birthdate
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.animal.birthdate) +
                              " - (" +
                              _vm._s(_vm.$getAge(_vm.animal.birthdate)) +
                              ")"
                          ),
                        ])
                      : _c("span", [_vm._v(" No date of birth provided ")]),
                  ]),
                  _c("display-row", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.genderName) +
                        ", " +
                        _vm._s(
                          _vm.getWeightStringFromAppointment(_vm.appointment)
                        ) +
                        " " +
                        _vm._s(_vm.animalHeightString) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("display-row", { staticClass: "font-semibold" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.appointment.user &&
                            _vm.appointment.user.display_name
                            ? _vm.appointment.user.display_name
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                  _c("display-row", {
                    attrs: {
                      "display-value":
                        _vm.appointment.user.mobile_phone_formatted,
                      "has-copy-button": true,
                    },
                  }),
                  _c("display-row", [
                    _vm._v(" " + _vm._s(_vm.appointment.user.email) + " "),
                  ]),
                  _c("animal-insurance-display-row", {
                    attrs: { animal: _vm.animal, "show-label": false },
                  }),
                  _vm.userIsInSweden
                    ? _c("display-row", {
                        attrs: {
                          "display-value": _vm.appointment.user.ssn,
                          "has-copy-button": true,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c(
                    "display-row",
                    {
                      staticClass:
                        "mt-5 w-full border-t border-beigeLight pt-3",
                      attrs: { "label-text": "Booking ID:" },
                    },
                    [_vm._v(" " + _vm._s(_vm.appointment.id) + " ")]
                  ),
                  _c(
                    "display-row",
                    {
                      staticClass: "w-full",
                      attrs: { "label-text": "Promo code:" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.appointment.promo && _vm.appointment.promo.id
                              ? _vm.appointment.promo.promo_code
                              : "none"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("meeting-display-rows", {
                    attrs: { appointment: _vm.appointment },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }