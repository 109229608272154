<template>
  <div
    v-if="audioScore || videoScore"
    class="flex flex-row space-x-2 bg-fv-black rounded-lg bg-opacity-50 text-gray-300 py-3 px-5 text-xs select-none"
  >
    <div
      v-if="audioScore"
      class="items-center inline-flex space-x-1"
    >
      <fv-icon
        :class="audioScore.color + ' svg-inline--fa'"
        icon="sound"
      />
      
      <span
        v-if="audioScore"
        class="text-gray-dark"
      >
        {{ audioScore.text }} ({{ audioScore.value ? audioScore.value : '' }})
      </span>
    </div>

    <div
      v-if="videoScore"
      class="items-center inline-flex space-x-1"
    >
      <fv-icon
        :class="videoScore.color + ' svg-inline--fa'"
        icon="video"
      />
      <span
        v-if="videoScore"
        class="text-gray-dark"
      >
        {{ videoScore.text }} ({{ videoScore.value ? videoScore.value: '' }})
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isForVet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    videoScore() {
      const videoScore = this.isForVet
        ? this.vetVideoScore
        : this.animalOwnerVideoScore;
      return videoScore;
    },
    audioScore() {
      const audioScore = this.isForVet
        ? this.vetAudioScore
        : this.animalOwnerAudioScore;
      return audioScore;
    },
    ...mapGetters('opentok', {
      vetVideoScore: 'getVetVideoScore',
      vetAudioScore: 'getVetAudioScore',
      animalOwnerVideoScore: 'getAnimalOwnerVideoScore',
      animalOwnerAudioScore: 'getAnimalOwnerAudioScore',
    }),
  },
};
</script>

<style lang="postcss">
</style>
