var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.startTime
    ? _c(
        "div",
        {
          staticClass:
            "flex h-full flex-col items-center justify-center p-2 text-lg text-white",
        },
        [
          _vm.appointment.status === _vm.APPOINTMENT_STATUS.pending
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass:
                      "mb-3 rounded border border-red-200 bg-red-100 p-4 text-red-900",
                  },
                  [_vm._v(" This is a pending booking ")]
                ),
              ])
            : _vm._e(),
          !_vm.hasStarted &&
          _vm.appointment.status !== _vm.APPOINTMENT_STATUS.pending
            ? _c("div", [
                _c("p", [_vm._v("Time until start")]),
                _c("p", { staticClass: "text-2xl font-black" }, [
                  _vm._v(" " + _vm._s(_vm.timeUntilStart) + " "),
                ]),
              ])
            : _vm._e(),
          _c("p"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }