<template>
  <div class="inline-flex items-center space-x-2">
    <base-button
      v-show="callStatus === CALL_STATUSES.answered"
      :id="type + '-hangup-btn'"
      tooltip="Click to end call"
      :loading="loading"
      :disabled="disabled || loading"
      class="width-1/3 mr-auto"
      color="danger"
      @click="endCall()"
    >
      <span class="relative inline-flex items-start space-x-2">
        <spinner-overlay size="xs" :loading="loading" />
        <fv-icon icon="end-call-40" />
      </span>
      <span class="relative ml-2 inline-flex items-end space-x-2 font-bold">
        Hang up
      </span>
    </base-button>

    <base-button
      v-show="callStatus !== CALL_STATUSES.answered"
      :id="type + '-call-btn'"
      v-tooltip="{
        content: !disabled
          ? tooltipText
          : `${type}-calls are disabled for this consultation.`,
      }"
      :loading="loading"
      :disabled="disabled || loading"
      color="light"
      @click="callStatus === CALL_STATUSES.connecting ? endCall() : startCall()"
    >
      <span class="relative inline-flex items-start space-x-2">
        <spinner-overlay size="xs" :loading="loading" />
        <fv-icon
          :class="{
            'animate-bounce': callStatus === CALL_STATUSES.connecting,
            'text-navy': callStatus === CALL_STATUSES.connecting,
          }"
          :icon="icons[type]"
        />
      </span>
      <span class="relative ml-2 inline-flex items-end space-x-2 font-bold">
        {{ buttonText }}
      </span>
      <span v-if="vonageCleanup && countdown > 0"> {{ countdown }}s </span>
    </base-button>
  </div>
</template>

<script>
export const CALL_STATUSES = {
  connecting: 'connecting',
  rejected: 'rejected',
  answered: 'answered',
  disconnected: 'disconnected',
  ended: 'hangup',
  noCall: 'no-call',
  vonageCleanup: 'vonage-cleanup',
};

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
      validator: value => ['video', 'IP'].includes(value),
    },
    callStatus: {
      type: String,
      default: null,
    },
    vonageCleanup: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      message: '',
      CALL_STATUSES: CALL_STATUSES,
      icons: {
        IP: 'call',
        video: 'video',
      },
      countdown: localStorage.getItem('video-cooldown') || 30,
    };
  },

  computed: {
    buttonText() {
      switch (this.callStatus) {
        case CALL_STATUSES.answered:
          return 'Hang up';
        case CALL_STATUSES.connecting:
          return 'Calling';
        case CALL_STATUSES.rejected:
          return 'Retry';
        case CALL_STATUSES.vonageCleanup:
          return 'Ending call: ';
        default:
          return `Start ${this.type} call`;
      }
    },
    tooltipText() {
      switch (this.callStatus) {
        case CALL_STATUSES.answered:
        case CALL_STATUSES.connecting:
          return 'Click to hang up';
        case CALL_STATUSES.disconnected:
          return 'Retry';
        case CALL_STATUSES.ended:
          return 'Call again';
        case CALL_STATUSES.rejected:
          return 'Customer declined call';
        case CALL_STATUSES.vonageCleanup:
          return 'Cleaning up call session';
        default:
          return this.description;
      }
    },
  },
  watch: {
    vonageCleanup(newVal) {
      if (newVal) {
        this.startCountdown();
      }
    },
  },

  mounted() {
    if (this.vonageCleanup) {
      this.startCountdown();
    }
  },

  methods: {
    endCall() {
      // todo do we need gtm when we have ids?
      this.$gtm.trackEvent({
        event: `clicked_end_${this.type}_call`,
      });
      this.$emit(`hang-up`);
    },
    startCall() {
      // todo do we need gtm when we have ids?
      this.$gtm.trackEvent({
        event: `clicked_make_${this.type}_call`,
      });
      this.$emit(`start-call`);
    },
    startCountdown() {
      if (this.vonageCleanup) {
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
            localStorage.setItem('video-cooldown', this.countdown);
          } else {
            this.countdown = 30;
            localStorage.removeItem('video-cooldown');
            clearInterval(timer);
          }
        }, 1000);
      }
    },
  },
};
</script>
