<template>
  <div class="border-t border-gray-800 py-3 px-5">
    <div class="flex items-center justify-between space-x-2">
      <div class="flex items-center space-x-2">
        <base-button v-if="connected" @click="toggleMic">
          <fv-icon
            :class="{ ' text-fv-red': !micEnabled }"
            :icon="micEnabled ? 'microphone' : 'microphone-disabled'"
          />
        </base-button>
        <base-button v-if="connected" @click="toggleCam">
          <fv-icon
            :class="{ ' text-fv-red': !videoEnabled }"
            :icon="videoEnabled ? 'video' : 'video-disabled'"
          />
        </base-button>
        <base-button
          v-if="connected || zoomed"
          @click="(zoomedValue = !zoomed), setZoomed(zoomedValue)"
        >
          <fv-icon
            fixed-width
            :icon="zoomed ? 'exit-fullscreen' : 'fullscreen'"
          />
        </base-button>

        <call-button
          v-if="!journal.status && !(journal.status && ended)"
          type="video"
          :call-status="callStatus"
          :description="message"
          :loading="activating"
          :disabled="
            vonageCleanup ||
            activating ||
            !appointment.id ||
            appointment.status === APPOINTMENT_STATUS.pending
          "
          :active="active"
          :vonage-cleanup="vonageCleanup"
          @start-call="startSession"
          @hang-up="vetHangUp"
        />
      </div>

      <div v-if="!active">
        <NexmoIPCallButton
          v-if="appointment.user"
          :phone-number="
            appointment.user.mobile_phone_formatted
              ? appointment.user.mobile_phone_formatted
              : appointment.user.phone
          "
          :locale="appointment.user.country.iso_3166_2"
        />
      </div>
      <div v-if="active" class="inline-flex flex-col space-y-1 text-white">
        <meeting-timer
          v-if="appointment.booking"
          :start-time="appointment.booking.booking_datetime"
          :slot-duration="appointment.booking.slot.duration"
        />
        <online-status-check class="flex items-center text-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Echo from '@/plugins/echo';
import MeetingTimer from '@/UI/meeting-timer.vue';
import CallButton, { CALL_STATUSES } from '@/UI/buttons/CallButton';
import OnlineStatusCheck from '@/components/interface/OnlineStatusCheck';
import NexmoIPCallButton from '@/components/ip-calls/NexmoIPCallButton';
import { APPOINTMENT_STATUS } from '@/utils/schedule-utils';

export default {
  components: {
    OnlineStatusCheck,
    MeetingTimer,
    NexmoIPCallButton,
    CallButton,
  },

  data() {
    return {
      isSessionConnected: false,
      callNotificationStatus: false,
      zoomedValue: false,
      listeningToVideoCallEvents: false,
      videoCallPushStatus: CALL_STATUSES.noCall,
      customerHungUp: false,
      APPOINTMENT_STATUS: APPOINTMENT_STATUS,
      vonageCleanup: !!localStorage.getItem('video-cooldown'),
    };
  },
  computed: {
    ...mapState('opentok', [
      'activating',
      'active',
      'animalOwnerOnline',
      'micEnabled',
      'message',
      'publisher',
      'session',
      'showPublisher',
      'stream',
      'subscriber',
      'vetOffline',
      'vetOnline',
      'videoEnabled',
      'zoomed',
    ]),
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
      loading: 'getLoading',
      booking: 'getBooking',
      archive: 'getArchive',
    }),
    ...mapState('journal', ['journal']),

    connected() {
      return this.session?.currentState === 'connected';
    },
    callStatus() {
      if (this.vonageCleanup) return CALL_STATUSES.vonageCleanup;

      if (this.videoCallPushStatus) return this.videoCallPushStatus;

      if (!this.session) return CALL_STATUSES.noCall;

      if (!this.customerHungUp && !this.animalOwnerOnline && this.connected)
        return CALL_STATUSES.connecting;

      if (this.connected) return CALL_STATUSES.answered;

      return this.session?.currentState;
    },

    ended() {
      if (!this.booking) return false;

      const durationTime = this.booking.slot
        ? (this.booking.slot.duration + this.booking.slot.margin) * 60000
        : 10 * 60000; //minutes to milliseconds, default 10 minutes
      const endTime =
        new Date(this.booking?.booking_datetime).getTime() + durationTime;

      return endTime < new Date().getTime();
    },
  },

  mounted() {
    this.initSession();
    this.listenToVideoCallEvents();

    if (localStorage.getItem('video-cooldown')) {
      this.handleVonageCleanup();
    }
  },
  beforeDestroy() {
    this.endSession();
    this.stopListenToVideoCallEvents();
    this.resetVideoCallEvents();
  },

  methods: {
    ...mapActions('opentok', [
      'setVetOnline',
      'setVetOffline',
      'setAnimalOwnerOnline',
      'setActive',
      'setActivating',
      'setAnimalOwnerAudioScore',
      'setAnimalOwnerVideoScore',
      'initSession',
      'connectSession',
      'toggleMicEnabled',
      'toggleVideoEnabled',
      'stopPublishing',
      'callNotification',
      'hangUp',
    ]),

    ...mapMutations('opentok', [
      'setMessage',
      'setStream',
      'toggleShowPublisher',
      'setZoomed',
    ]),

    togglePublisher() {
      this.toggleShowPublisher(!this.showPublisher);
    },
    toggleMic() {
      this.toggleMicEnabled(!this.micEnabled);
    },
    toggleCam() {
      this.toggleVideoEnabled(!this.videoEnabled);
    },

    async startSession() {
      if (this.active || this.activating) {
        return;
      }

      this.resetVideoCallEvents();
      this.setActivating(true);

      await this.initSession();

      this.toggleShowPublisher(true);

      if (this.session.currentState === 'connected') {
        // Session is already connected.
        // This can happen if vet navigates from meeting room whilst this.session.connect() is running.
        // this.isSessionConnected is in that case still false when this component gets destroyed and
        // therefor session doesn't disconnect in endSession().
        this.isSessionConnected = true;
      } else {
        await this.connectSession().catch(e => {
          this.setActivating(false);
          this.errorHandler(e);
        });

        this.isSessionConnected = true;
      }
      this.setActive(true);

      this.toggleMicEnabled(true);
      this.toggleVideoEnabled(true);

      this.addSessionEventListeners();

      this.setActivating(false);
    },

    vetHangUp() {
      this.endSession();
      this.hangUpVideoCall();
    },

    endSession() {
      if (this.isSessionConnected) {
        this.stopPublishing();

        if (this.subscriber && this.stream) {
          this.session.unsubscribe(this.subscriber);
        }
        if (this.session) {
          this.session.disconnect();
        }
      }

      if (this.videoCallPushStatus === CALL_STATUSES.answered) {
        this.resetVideoCallEvents();
      }
      this.setStream(null);

      this.setAnimalOwnerOnline(false);

      this.setActive(false);
      this.setActivating(false);
      this.toggleShowPublisher(false);
      this.toggleMicEnabled(false);
      this.toggleVideoEnabled(false);
    },

    addSessionEventListeners() {
      this.session.on({
        streamCreated: event => {
          this.setStream(event.stream);
          this.setAnimalOwnerOnline(true);
        },
        streamDestroyed: () => {
          this.setStream(null);
          this.setAnimalOwnerOnline(false);
          this.setMessage('Customer left the call');
          this.customerHungUp = true;
        },
        sessionDisconnected: () => {
          this.isSessionConnected = false;
        },
        sessionConnected: () => {
          setTimeout(() => this.startCallNotification(), 500);
        },
      });
    },

    async listenToVideoCallEvents() {
      Echo.private(`appointment.${this.appointment.id}`).listen(
        '.new.video-call-event',
        e => {
          this.videoCallPushStatus = e.event;
          this.setMessage(e.description);
          if (e.event === 'rejected') {
            this.endSession();
          }
        }
      );
      this.listeningToVideoCallEvents = true;
    },

    async stopListenToVideoCallEvents() {
      if (this.listeningToVideoCallEvents) {
        Echo.leave(`appointment.${this.appointment.id}`);
        this.listeningToVideoCallEvents = false;
      }
    },

    resetVideoCallEvents() {
      this.setMessage('');
      this.videoCallPushStatus = '';
    },

    errorHandler(err) {
      this.$notify({
        group: 'error',
        title: 'Something went wrong',
        text: err.message,
        closeOnClick: true,
        ignoreDuplicates: true,
      });
    },

    async startCallNotification() {
      if (this.callNotificationStatus) return;
      this.callNotificationStatus = true;
      if (this.appointment.id) {
        await this.callNotification({
          appointmentId: this.appointment.id,
        });
        this.callNotificationStatus = false;
      }
    },

    async hangUpVideoCall() {
      this.callNotificationStatus = false;
      this.hangUp({
        appointmentId: this.appointment.id,
      });
      this.handleVonageCleanup();
    },

    // Coldown on call button for 30s
    // Ensures correct session ending of previous call before starting a new call
    // 30s is minimum for this to work
    handleVonageCleanup() {
      this.vonageCleanup = true;
      const timeoutLocalstorage =
        localStorage.getItem('video-cooldown') &&
        parseInt(localStorage.getItem('video-cooldown'), 10) * 1000;
      setTimeout(() => {
        this.vonageCleanup = false;
      }, timeoutLocalstorage || 30000);
    },
  },
};
</script>
