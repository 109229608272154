<template>
  <div
    v-if="appointment && appointment.booking"
    id="infoBox"
    class="mb-8 flex flex-col overflow-auto border-t bg-white bg-opacity-75 text-navy shadow-md"
  >
    <div
      v-if="journalIsSigned"
      class="w-full bg-fv-green px-5 py-4 font-semibold text-white"
    >
      <div class="flex items-center space-x-3">
        <fv-icon icon="check-mark-alt" />
        <span class="text-sm">
          Journal signed.
          {{
            journal.notified ? `Email was delivered at ${journal.notified}` : ''
          }}
        </span>
      </div>
    </div>

    <div
      class="infoBox grid grid-flow-row grid-cols-2 px-5 py-5 leading-normal"
    >
      <!-- first column -->
      <div>
        <display-row
          v-if="animal && animal.name"
          class="justify-items-center font-semibold"
        >
          {{ animal.name }}
        </display-row>

        <display-row>
          {{ animal.animal_type ? animal.animal_type.name : '' }}
          {{ animal.race ? ' - ' + animal.race : '' }}
        </display-row>

        <display-row>
          <span v-if="animal && animal.birthdate"
            >{{ animal.birthdate }} - ({{ $getAge(animal.birthdate) }})</span
          >
          <span v-else> No date of birth provided </span>
        </display-row>

        <display-row>
          {{ genderName }},
          {{ getWeightStringFromAppointment(appointment) }}
          {{ animalHeightString }}
        </display-row>
      </div>
      <!-- end first column -->

      <!-- second column -->
      <div>
        <display-row class="font-semibold">
          {{
            appointment.user && appointment.user.display_name
              ? appointment.user.display_name
              : ''
          }}
        </display-row>

        <display-row
          :display-value="appointment.user.mobile_phone_formatted"
          :has-copy-button="true"
        />

        <display-row>
          {{ appointment.user.email }}
        </display-row>

        <animal-insurance-display-row :animal="animal" :show-label="false" />

        <display-row
          v-if="userIsInSweden"
          :display-value="appointment.user.ssn"
          :has-copy-button="true"
        />
      </div>
      <!-- end second column -->

      <!-- start second row - spans both columns -->

      <div class="col-span-2">
        <display-row
          class="mt-5 w-full border-t border-beigeLight pt-3"
          label-text="Booking ID:"
        >
          {{ appointment.id }}
        </display-row>

        <display-row label-text="Promo code:" class="w-full">
          {{
            appointment.promo && appointment.promo.id
              ? appointment.promo.promo_code
              : 'none'
          }}
        </display-row>

        <meeting-display-rows :appointment="appointment" />
      </div>
      <!-- end second row - spans both columns -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/utils/helpers/time-helpers';
import { getWeightStringFromAppointment } from '@/utils/helpers/formatting-helpers';
import MeetingDisplayRows from '@/components/booking/booking_info_rows/MeetingDisplayRows';
import AnimalInsuranceDisplayRow from '@/components/booking/booking_info_rows/AnimalInsuranceDisplayRow';
import { US_COUNTRY_ID } from '@/config/countries';

export default {
  components: {
    MeetingDisplayRows,
    AnimalInsuranceDisplayRow,
  },
  computed: {
    ...mapState('appointment', ['appointment']),
    ...mapState('animal', ['animal', 'genderOptions']),
    ...mapState('journal', ['journal']),
    ...mapState(['platform']),

    userIsInSweden() {
      return this.platform === 'sv';
    },

    genderName() {
      return (
        this.genderOptions.find(gender => gender.value === this.animal.gender)
          ?.name || 'No gender provided'
      );
    },

    journalIsSigned() {
      return !!this.journal?.status;
    },

    animalHeightString() {
      const countryIsUSA = this.appointment.user.country_id === US_COUNTRY_ID;
      const heightSuffix = countryIsUSA ? ' in.' : ' cm';

      return this.animal.height && parseInt(this.animal.height) > 0
        ? this.animal.height + heightSuffix
        : '';
    },
  },
  methods: {
    getWeightStringFromAppointment,
  },
};
</script>
