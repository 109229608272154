var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inline-flex items-center space-x-2" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.nexmoButtonText
          ? _c("p", { staticClass: "text-white" }, [
              _vm._v(" " + _vm._s(_vm.nexmoButtonText) + " "),
            ])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.error
          ? _c("p", { staticClass: "text-red-300" }, [
              _vm._v(" " + _vm._s(_vm.error) + " "),
            ])
          : _vm._e(),
      ]),
      _c("call-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.videoCallActive,
            expression: "!videoCallActive",
          },
        ],
        attrs: {
          "call-status": _vm.connectionStatus,
          disabled:
            _vm.disableIPCalls ||
            !_vm.userNumber ||
            _vm.videoCallActive ||
            _vm.loading,
          loading: _vm.loading,
          type: "IP",
        },
        on: {
          "start-call": function ($event) {
            return _vm.callUser()
          },
          "hang-up": function ($event) {
            return _vm.hangUp()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }